import React, { useEffect } from 'react'
import { InlineWidget } from 'react-calendly'
import styled from 'styled-components'
import device from '../utils/mq'
import SEO from '../components/Seo'

const Wrapper = styled.div`
  background: #fbfcfd;
  width: 100%;
  padding-top: 50px;
`

const AppointmentWrapper = styled.div`
  max-width: 1300px;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  padding: 0px 1.0875rem;
  margin-top: 20px;

  @media screen and (min-width: 740px) {
  }
`

const AppointmentContentContainer = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  height: 1200px;

  & > iframe {
    border: none;
    width: 100%;
    height: 100%;
  }

  @media ${device.mobileL} {
    height: 1250px;
  }

  @media ${device.laptop} {
    height: 820px;
    margin: 0 auto 50px;
  }
`

const AppointmentTitleContainer = styled.div`
  text-align: center;
  max-width: 560px;
  margin: 0 auto;
  padding-bottom: 40px;
  @media ${device.tablet} {
    padding-bottom: 0px;
  }
`

const AppointmentTitle = styled.h1`
  color: #000;
  font-size: 32px;
  line-height: 52px;
  font-weight: 900;
  margin: 0;

  @media ${device.tablet} {
  }
`

const AppointmentInfo = styled.p`
  color: #000;
  font-size: 16px;
  line-height: 26px;
  font-weight: normal;
  margin-top: 5px;

  @media ${device.tablet} {
  }
`

// const iframe =
//   '<iframe src="https://calendly.com/innentialdaniel/learn" ></iframe>'

const Appointment = () => {
  const safeWindow = typeof window !== 'undefined' ? window : {}

  const params = new URLSearchParams(safeWindow?.location?.search)

  const email = params.get('email')
  const name = params.get('name')

  return (
    <>
      <SEO
        title="Innential - Book a call"
        description="Let's chat!"
        meta={[{ name: 'robots', content: 'noindex' }]} // PREVENT INDEXING THE PAGE
      />
      <Wrapper>
        <AppointmentWrapper>
          <AppointmentTitleContainer>
            <AppointmentTitle>Let’s talk</AppointmentTitle>
            <AppointmentInfo>
              As a next step, please schedule a 20-minute call to learn more
              about our platform and determine if it’s a fit for your goals.
            </AppointmentInfo>
          </AppointmentTitleContainer>
          <AppointmentContentContainer>
            <InlineWidget
              url="https://calendly.com/innentialdaniel/learn"
              // url="https://calendly.com/innential/innential-introduction-with-kris"
              styles={{
                height: 800,
              }}
              prefill={{
                email,
                name,
              }}
            />
          </AppointmentContentContainer>
        </AppointmentWrapper>
      </Wrapper>
    </>
  )
}

export default Appointment
